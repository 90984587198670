import React, { useEffect, useState } from "react";
import "./LEAFmaincontent.css";
//import vote from "../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../../images/TopWeb-May-2024.jpg"; //  TopWeb - logo

const LEAFmaincontent = () => {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 600);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  const dotStyle = {
    width: "25px",
    marginTop: "4px",
    height: "25px",
    borderRadius: "50%",
    backgroundColor: "#90cd00",
  };

  return (
    <div className="responsive-leaf">
      <p className="para-leaf">
        <br />
        <br />
        <br />
        <br />
        LEAF (Lalan Environmental Action Fund) is the non-profit charitable arm
        of the Lalan Group dedicated entirely to wildlife and environmental
        conservation efforts. LEAF follows in the Lalan Group's intrinsic belief
        that true success and progress is built on two key guiding principles -
        nurturing the lives of the people that work with us, and protecting the
        environment around us. It is a belief that runs deep - not just as part
        of the ethos of our company, but also built into the decisions that are
        taken at every level. With the Lalith Hapangama Foundation focusing on
        social responsibility and sustainability, LEAF tackles the need to make
        more holistic, measurable, and sustainable changes in the symbiotic
        relationship we share with our environment.
        <br />
        <br />
        <br />
        LEAF was established with the aim of driving these changes through a
        structured, three-pronged approach: reduce, restore, and revitalize.
        <br />
        <br />
        <br />
        Our products range from premium quality organic natural latex mattresses
        and pillows, high tensile strength, biodegradable rubber bands and tubes
        to premium industrial gloves and cutting-edge seamless dipped gloves.
        Lalan's seamless network of latex suppliers ensures efficiency and
        consistency of our centrifuge operations and our superior quality crepe
        rubber and RSS are produced to exacting international standards with a
        minimum environmental impact.
        <br />
        <br />
        <br />
        We believe in sustainable development and our outgrower programs, which
        support rubber smallholders, are an integral part of the development of
        the rubber industry in Sri Lanka.
        <br />
        <br />
      </p>
      <div className="table-leaf">
        <table style={{ width: "100%", textAlign: "start" }}>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Reducing the impact, direct and indirect, of all Lalan
                production facilities on the environment.
                <br />
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Restoring the balance of affected and endangered environments,
                through comprehensive and long-term programs driven by experts
                and professionals.
                <br />
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Revitalizing the biosphere of formerly affected areas,
                introducing sustainable systems to prevent any future negligence
                and damage.
                <br />
                <br />
              </p>
            </td>
          </tr>
        </table>
      </div>
      <p className="para-leaf">
        <br />
        To this effect, LEAF has actioned several key sustainability projects
        undertaken by the Group recently:
        <br />
        <br />
      </p>
      <div className="table-leaf">
        <table style={{ width: "100%", textAlign: "start" }}>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Rainwater Harvesting - By introducing rainwater harvesting
                systems at their facilities, Lalan Rubbers - the glove
                production arm of Lalan Group - has been able to reduce their
                water footprint by 25%.
                <br />
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Biomass Energy Generation - Most facilities use 100% sustainably
                farmed and sourced biomass for their heating energy
                requirements. By switching from fossil fuels to biomass, we have
                not only reduced our cost of production, but have also
                significantly reduced our carbon footprint.
                <br />
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Replanting Initiatives - In the plantation industry, annual
                replanting is a necessity to ensure the continued health of both
                the land and the trees on the plantations. In addition to
                replanting, we carry out interplanting with compatible crops
                that ease the strain on the land. Through this initiative, over
                100,000 trees are added to the eco-system annually.
                <br />
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Land Conservation - We also maintain non-commercial land
                holdings which are being replanted with endemic species of flora
                in order to conserve and protect the native eco-system.
                <br />
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", verticalAlign: "top" }}>
              <p className="para-leaf">
                <div style={dotStyle}></div>
              </p>
            </td>
            <td style={{ width: "auto", verticalAlign: "top" }}>
              <p className="para-leaf">
                Carbon Neutrality Programs - Through a combination of our
                plantations, replanting initiatives, and land conservation
                efforts we are sequestering up to 109,000 tons of greenhouse gas
                emissions over the next 30 years.
                <br />
                <br />
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div className="ima1">
        <img
          src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/leaf/foot-banner.png"
          alt="image"
        />
      </div>

      {/* BestWeb  */}
      <div
        style={{
          marginLeft: isMobile ? "-20px" : "0px",
          alignItems: "start",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* BestWeb - start */}
          {/* <div>
            <a
              href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
              target="_blank"
            >
              <img className="logopic" src={vote} alt="logo" />
            </a>
          </div> */}
          {/* BestWeb - end */}

          {/* TopWeb - start */}
          <div>
            <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
              <img
                style={{ marginLeft: "30px" }}
                className="logopic-2"
                src={TopWeb}
                alt="logo"
              />
            </a>
          </div>
          {/* TopWeb - end */}
        </div>
      </div>

      {/*  new line start $ vote */}
      <br />
      <br />
      <br />
      {/*  new line end */}
      <br />
      <br />
      <br />
    </div>
  );
};

export default LEAFmaincontent;
