import React, { useState, useRef, useEffect } from "react";
import NavBar from "../../components/navbar";
import "../../components/homevideo.css";
import LEAFmaincontent from "../../template/LEAF/LEAFmaincontent";
import Footer3CS from "../../components/footerbar";
import Arrow from "../../template/ScrollToTopButton";
import {
  Parallax,
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";
import { Helmet } from "react-helmet";

const Leaf = () => {
  const setScrollY = useState(0)[1];
  const mainRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    setScrollY(100);
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ParallaxProvider>
      <div style={{ backgroundColor: "#1f1f1f" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            LEAF | Lalan Environmental Action Fund | Lalan Group Sri Lanka
          </title>
          <meta
            name="title"
            content="LEAF | Lalan Environmental Action Fund | Lalan Group Sri Lanka"
          />
          <meta
            name="description"
            content="LEAF is the non-profit charitable arm of the group targeted towards wildlife and environmental conservation efforts. Take a look at the several projects that have been undertaken by the group. "
          />
          <meta name="keywords" content="Lalan Environmental Action Fund" />
          <link
            rel="canonical"
            href="https://www.lalangroup.com/sustainability/leaf/"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Leaf - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/sustainability/leaf/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>

        <style>
          {`
            /* Customize scrollbar */
            ::-webkit-scrollbar {
              width: 4px;
              background-color: #1f1f1f;
            }

            ::-webkit-scrollbar-thumb {
              background-color: #5689c7;
              background-clip: content-box;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: #5689c7;
            }

            ::-webkit-scrollbar-track {
              background-color: #1f1f1f;
            }
          `}
        </style>

        <NavBar />
        <div style={{ position: "relative" }}>
          <ParallaxBanner style={{ aspectRatio: "2 / 1.124", height: "100vh" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/top-banner6.jpg"
              speed={13}
            />
          </ParallaxBanner>
          <div className="top-font-block">
            <Parallax speed={10}>
              <img
                className="video-font-png"
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/leaf.png"
                alt="font"
              />
            </Parallax>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                onClick={handleClick}
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/uploads/images/gif-mouse.gif"
                alt="button"
                className="mouse-button"
                style={{ height: "90px", zIndex: 10 }}
              />
            </div>
          </div>
        </div>

        <div ref={mainRef}>
          {/* Main content */}
          <div
            style={{
              marginLeft: "170px",
              marginRight: "170px",
              marginTop: "30px",
            }}
          >
            <LEAFmaincontent />
          </div>
        </div>
        <Footer3CS />
        <Arrow />
      </div>
    </ParallaxProvider>
  );
};

export default Leaf;
